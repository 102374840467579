<template>
	<div class="container">
        <SearchTop />
		<NavBottom />
		<div class="wtopbg">
			<img style="width: 100%;" src="../assets/topBg.jpg"/>
			<div class="wtopIn">
				<div class="wInfos">
					我的积分
					<div class="bigText">
						0
					</div>
				</div>
				<div class="signBtn">
					签到
				</div>
			</div>
			<div class="contWrp">
				<div class="signTip">
					已连续签到 <text>0天</text>
				</div>
				<div class="ifnTip">
					明日签到可获得 <text>0分</text>
				</div>
				<div class="dateWrp">
					<div class="dates">
						<div class="datebg on">
                            <img class="check-icon" src="../assets/check.png" alt="check">
						</div>
						<text>周一</text>
					</div>
					<div class="dates">
						<div class="datebg on">
                            <img class="check-icon" src="../assets/check.png" alt="check">
						</div>
						<text>周二</text>
					</div>
					<div class="dates">
						<div class="datebg on">
                            <img class="check-icon" src="../assets/check.png" alt="check">
						</div>
						<text>周三</text>
					</div>
					<div class="dates">
						<div class="datebg on">
                            <img class="check-icon" src="../assets/check.png" alt="check">
						</div>
						<text>周四</text>
					</div>
					<div class="dates">
						<div class="datebg on">
                            <img class="check-icon" src="../assets/check.png" alt="check">
						</div>
						<text>周五</text>
					</div>
					<div class="dates">
						<div class="datebg on">
                            <img class="check-icon" src="../assets/check.png" alt="check">
						</div>
						<text>周六</text>
					</div>
					<div class="dates">
						<div class="datebg on">
                            <img class="check-icon" src="../assets/check.png" alt="check">
						</div>
						<text>周日</text>
					</div>
				</div>
			</div>
		</div>
		<div class="tit">
			积分任务
		</div>
		<div class="signMisson">
			<div class="signMis">
				<img src="../assets/signPid.png"  style="width: 33px;"/>
				<text>注册赠送10积分</text>
				<div class="getzMis">
					领取
				</div>
			</div>
			
		</div>
		<div class="signZCnt">
			<div style="text-align: center;font-size: 33rpx;">签到规则</div>
			<div>
				1.签到领积分：用户可以每日前往签到页面进行签到，首日可领1个积分，连续签到每日可递增1个积分，连续签到每日可领取积分上限为7个积分；若签到终端则重新计算。
			</div>
			<div>2.最终解释权归平台所有</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		onShow() {
			alert('待开发中...')
		},
		
	}
</script>

<style scoped>
	.container{
        background-image: linear-gradient(to bottom,#f9420a,#fefefe);
        padding-bottom:22px;
    }
	.tit{
		margin: 12px;
		font-size: 36rpx;
	}
	.signZCnt{
		margin: 8px;
	}
	.getzMis{
		background-color: #F97A0A;
		color: #fff;
		border-radius: 22px;
		width: 90px;
		line-height: 41px;
		text-align: center;
	}
	.signMisson{
		margin: 12px;
		padding: 12px 0;
		border: 1px solid #ccc;
		border-radius: 8px;
		
		background-color: #fff;
	}
	.signMis{
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.dateWrp {
		padding: 12px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.datebg {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 26px;
		width: 26px;
		border-radius: 50%;
		background-color: #ccc;
	}
    .check-icon{
        width: 14px;
    }
	.dates text {
		font-size: 26rpx;
		line-height: 2.6;
		color: #999;
	}

	.datebg.on {
		background-color: #F97A0A;
	}

	.signTip {
		font-size: 43rpx;
	}

	.ifnTip {
		font-size: 24rpx;
		color: #999;
	}

	.signTip text,
	.ifnTip text {
		color: #F97A0A;
		padding-left: 8px;
	}

	.contWrp {
		position: absolute;
		top: 50%;
		left: 12px;
		right: 12px;
		bottom: -50%;
		padding: 12px;
		background-color: #fff;
		border-radius: 8px;
		border: 1px solid #ccc;
	}

	.wtopbg {
		position: relative;
		margin-bottom: 25%;
	}

	.wtopIn {
		display: flex;
		justify-content: space-around;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 50%;
	}

	.wInfos {
		color: #fff;
	}

	.bigText {
		text-align: center;
		font-size: 46rpx;
	}

	.signBtn {
		border-radius: 22px;
		height: 43px;
		line-height: 43px;
		font-size: 33rpx;
		color: #fff;
		background-color: #F97A0A;
		text-align: center;
		width: 120px;
	}
</style>
